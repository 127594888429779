import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import logo from "/src/images/iso_color.png";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Warranty = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Privacy Policy | Custom Surgical" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1200px",
          paddingTop: "4em",
          marginBottom: "2em",
        }}
      >
        <div className="container3" id="footer_page_flex">
          <div id="footer_page_width1">
            <h1 style={{ marginTop: "0" }}>Privacy Policy</h1>
            <p style={{ color: "#9c9c9c" }}>Last updated August 5, 2021</p>
          </div>
          <div id="footer_page_width2">
            <p>
              Thank you for choosing to be part of our community at Custom
              Surgical GmbH ("Company", "we", "us", "our"). We are committed to
              protecting your personal information and your right to privacy. If
              you have any questions or concerns about this privacy notice, or
              our practices with regards to your personal information, please
              contact us at{" "}
              <a href="mailto:support@customsurgical.co" className="links_post">
                support@customsurgical.co
              </a>{" "}
            </p>
            <p>
              When you visit our website{" "}
              <a href="https://customsurgical.co" className="links_post">
                https://customsurgical.co
              </a>{" "}
              (the "Website"), and more generally, use any of our services (the
              "Services", which include the Website), we appreciate that you are
              trusting us with your personal information. We take your privacy
              very seriously.{" "}
            </p>
            <p>
              In this privacy notice, we seek to explain to you in the clearest
              way possible what information we collect, how we use it and what
              rights you have in relation to it. We hope you take some time to
              read through it, carefully, as it is important. If there are any
              terms in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.{" "}
            </p>
            <p>
              This privacy notice applies to all information collected through
              our Services (which, as described above, includes our Website), as
              well as any related services, sales, marketing or events.{" "}
            </p>
            <p>
              <b>
                Please read this privacy notice carefully as it will help you
                understand what we do with the information that we collect.
              </b>
            </p>
            <h1 className="container3 reveal fade-right">
              Collection and usage
            </h1>
            <p className="container3 reveal fade-left">
              We collect personal information that you voluntarily provide to us
              when you register on the Website, express an interest in obtaining
              information about us or our products and Services, when you
              participate in activities on the Website (such as by posting
              messages or comments in our online chats, activating warranty, or
              entering competitions, contests or giveaways) or otherwise when
              you contact us.{" "}
            </p>
            <p className="container3 reveal fade-left">
              The personal information that we collect depends on the context of
              your interactions with us and the Website, the choices you make
              and the products and features you use.{" "}
            </p>
            <div className="container3 reveal fade-left">
              <ul>
                <b>Personal data: </b>
              </ul>
              <li>• First and last name </li>
              <li>• Email </li>
              <li>• Phone number </li>
              <li>
                • Shipping address, and billing address (if the billing address
                is different than shipping address)
              </li>
              <li>• VAT number (optional)</li>
              <li>• Payment information </li>
              <li>• Country of residence </li>
              <li>• Picture (optional)</li>
              <li>• Resume or CV data (optional)</li>
            </div>
            <div className="container3 reveal fade-left">
              <ul>
                <b>Why do we use it:</b>
              </ul>
              <li>
                • To fulfill and manage your orders, payments, returns, and
                exchanges made through the Website
              </li>
              <li>• To respond to user inquiries/offer support to users</li>
              <li>
                • To send you the product, service and new feature information
                and/or information about changes to our terms, conditions, and
                policies
              </li>
              <li>• Requesting your feedback</li>
              <li>
                • To post your testimonials on our Website that may contain
                personal information (in the case of receiving your prior
                explicit consent for such publication)
              </li>
              <li>
                • We may use your information as part of our efforts to keep our
                Website safe and secure (for example, for fraud monitoring and
                prevention)
              </li>
              <li>
                • To enforce our Terms, conditions, and policies for business
                purposes, to comply with legal and regulatory requirements,
                or/and in connection with the performance of mutual obligation
                between you and us
              </li>
              <li>
                • To respond to legal requests and prevent harm and fraudulent
                actions of third parties
              </li>
              <li>
                • To administer prize draws and competitions when you elect to
                participate in our competitions
              </li>
              <li>
                • To send you marketing and promotional communications. We may
                use the personal information you send to us for our marketing
                purposes, if this is in accordance with your marketing
                preferences. For example, when expressing an interest in
                obtaining information about us or our Website, subscribing to
                marketing or otherwise contacting us, we will collect personal
                information from you. You can opt-out of our marketing emails at
                any time (see the "WHAT ARE YOUR PRIVACY RIGHTS?" below)
              </li>
            </div>
            <p className="container3 reveal fade-left">
              We don’t store your payment card details and we process payments
              through Stripe.
            </p>
            <p className="container3 reveal fade-left">
              You also have the choices to send us your resume and/or CV
              (Curriculum vitæ) in case you are looking for a job and want to
              cooperate with us. By sending us a resume and/or CV you agree to
              give us your personal data, such as your education and scientific
              degrees, work experience, professional and other skills, your
              photo, etc. You don’t have to provide any additional information
              in your resume or in any other way.
            </p>
            <p className="container3 reveal fade-left">
              We do not have any requirements to resumes or CVs that might be
              sent to us. We should inform you that it is your personal choice
              to add your sensitive personal data (racial or ethnic origin,
              beliefs; trade-union membership; genetic data, biometric data;
              health-related data; data concerning your sex life or sexual
              orientation) on your resume or CV, as well as to send this
              information to us.
            </p>
            <p className="container3 reveal fade-left">
              We kindly ask you not to add any personal data you do not want to
              be publicly available to your resume or CV.
            </p>
            <p className="container3 reveal fade-left">
              All personal information that you provide to us must be true,
              complete and accurate. Please, notify us about any changes in your
              personal data, because in another case we'll not be able to
              provide to you our services in a timely and proper manner.{" "}
            </p>
            <p className="container3 reveal fade-left">
              We may use your personal data for other business purposes, such as
              data analysis, identifying usage trends, determining the
              effectiveness of our promotional campaigns and evaluating and
              improving our Website, products, marketing and your experience. We
              may use and store this information in aggregated and anonymized
              form so that it is not associated with individual end-users and
              does not include personal information.{" "}
            </p>
            <p className="container3 reveal fade-left">
              Anyway, we will not use identifiable personal information without
              your consent.
            </p>
            <h3 className="container3 reveal fade-right">
              Third-party services
            </h3>
            <p className="container3 reveal fade-left">
              Our Service may contain links to and from third parties’ websites,
              apps or other services (including social media). If you follow a
              link to any of them, please note that those websites have their
              own privacy practices. We shall not be liable for those respective
              policies and any collection, use or disclosure of your Personal
              Data in accordance thereto. Please check the third parties’
              privacy policies before you submit any Personal Data to their
              services.
            </p>
            <p className="container3 reveal fade-left">
              By general rule, we don’t disclose or sell your personal
              information unless otherwise is not specified in this Privacy
              Policy. We only share information with your consent, to comply
              with laws, to provide you with services, to protect your rights,
              or to fulfill business obligations.
            </p>
            <div className="container3 reveal fade-left">
              <ul>
                We may use some or all of the following services that may
                collect aggregated information or ask you to log in with them
                (in case of login or registration on any of those services, we
                don’t have responsibility for your experience with them):
              </ul>
              <li>
                <b>1. Advertising, Direct Marketing, and Lead Generation:</b>{" "}
                Bing Ads, Google AdSense, Facebook Audience Network, Mailchimp,
                Streak and Google Analytics, AmoCRM, Whatsapp for Business{" "}
              </li>
              <li>
                <b>2. Content Optimization:</b> WooCommerce, Wordpress, YouTube
                video embed and Google Site Search{" "}
              </li>
              <li>
                <b>3. Invoice and Billing:</b> Stripe{" "}
              </li>
              <li>
                <b>4. Retargeting Platforms:</b> Facebook Remarketing and Google
                Ads Remarketing{" "}
              </li>
              <li>
                <b>5. Social Media Sharing and Advertising:</b> Facebook
                advertising{" "}
              </li>
              <li>
                <b>6. User Commenting and Forums:</b> Disqus{" "}
              </li>
              <li>
                <b>7. Web and Mobile Analytics:</b> Google Analytics{" "}
              </li>
              <li>
                <b>8. Shipping services</b>
              </li>
            </div>
            <p className="container3 reveal fade-left">
              We may use information necessary to ensure our services and share
              it with service providers, such as logistics, shipping, and
              fulfillment partners or to respond to legal requests and vital
              interests. We may share or transfer your information in connection
              with any merger, sale of company assets, financing, or acquisition
              of all or a portion of our business to another company.
            </p>
            <h1 className="container3 reveal fade-right">
              "Cookies" and automatically collected data
            </h1>
            <p className="container3 reveal fade-left">
              We use some third-party services that may collect some aggregated
              user information. If we have processed your data based on your
              consent and you wish to revoke your consent, please contact us{" "}
              <a href="mailto:support@customsurgical.co" className="links_post">
                support@customsurgical.co
              </a>{" "}
              or just don’t give cookie consent when you visit the website.{" "}
            </p>
            <p className="container3 reveal fade-left">
              We automatically collect certain information when you visit, use
              or navigate the Website. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Website and other
              technical information.{" "}
            </p>
            <p className="container3 reveal fade-left">
              This information is primarily needed to maintain the security and
              operation of our Website, and for our internal analytics and
              reporting purposes.
            </p>
            <p className="container3 reveal fade-left">
              Like many businesses, we also collect information through cookies
              and similar technologies.{" "}
            </p>
            <div className="container3 reveal fade-right">
              <ul>The information we collect includes: </ul>
              <li>
                <b>1. Log and Usage Data.</b> Log and usage data is
                service-related, diagnostic, usage and performance information
                our servers automatically collect when you access or use our
                Website and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type and settings and information
                about your activity in the Website (such as the date/time stamps
                associated with your usage, pages and files viewed, searches and
                other actions you take such as which features you use), device
                event information (such as system activity, error reports
                (sometimes called 'crash dumps') and hardware settings).{" "}
              </li>
              <li>
                <b>2. Device Data. </b>We collect device data such as
                information about your computer, phone, tablet or other device
                you use to access the Website. Depending on the device used,
                this device data may include information such as your IP address
                (or proxy server), device and application identification
                numbers, location, browser type, hardware model, Internet
                service provider and/or mobile carrier, operating system and
                system configuration information.{" "}
              </li>
              <li>
                <b>3. Location Data.</b> We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Website. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt-out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location settings on your
                device. Note, however, if you choose to opt-out, you may not be
                able to use certain aspects of the Services.
              </li>
            </div>
            <p className="container3 reveal fade-left">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
            <h1 className="container3 reveal fade-right">
              Data storage, security and cross-border transfer
            </h1>
            <p className="container3 reveal fade-left">
              We will use all reasonable measures to protect your Personal Data
              from unauthorized access, alteration, disclosure or removal. The
              Service is equipped with the means of security and protection of
              your Personal Data.
            </p>
            <p className="container3 reveal fade-left">
              Immediate access to your Personal Data is allowed only to us and
              persons authorized by us and involved in the maintenance of the
              Service. Such persons will strictly keep the confidentiality of
              and prevent unauthorized third-party access to your Personal Data.
            </p>
            <p className="container3 reveal fade-left">
              Please remember that our Service may be hosted on a server outside
              your home country. By supplying us with your Personal Data, you
              permit its transfer outside the country, within the bounds of use
              of such Personal Data in accordance with the applicable law.
            </p>
            <p className="container3 reveal fade-left">
              We emphasize that none of the existing ways of data transfer can
              be absolutely safe. The Company does not guarantee the absolute
              security of data in case of unauthorized access of third parties.
            </p>
            <p className="container3 reveal fade-left">
              We shall not be responsible for unlawful acts of third parties,
              hackers, intruders, and other offenders of the applicable
              legislation that may violate the provisions of this Privacy Policy
              and try to collect, either fully or in part, your Personal Data
              and to use it for personal advantage.
            </p>
            <p className="container3 reveal fade-left">
              As a general rule, the time limit for personal data processing and
              storage in our services is 7 (seven) years from the date of your
              last activity or deletion of your account, which is ordinarily
              necessary to comply with applicable tax/revenue laws, resolve
              disputes, and enforce our agreements.
            </p>
            <h1 className="container3 reveal fade-right">Children's privacy</h1>
            <p className="container3 reveal fade-left">
              We do not collect, use or share the Personal Data of children
              (under the age of majority per the applicable law). You must be a
              legal adult to access our Service under the applicable law and the
              law of the country where you are located.
            </p>
            <h1 className="container3 reveal fade-right">
              Your privacy rights
            </h1>
            <p className="container3 reveal fade-left">
              In accordance with General Data Protection Regulation, you have
              the following rights:
            </p>
            <p className="container3 reveal fade-left">
              Right to Access Personal Data - the right to access your personal
              data collected by us.
            </p>
            <p className="container3 reveal fade-left">
              Right to Rectification - to request modification of your data,
              including the correction or errors and the updating of incomplete
              information.
            </p>
            <p className="container3 reveal fade-left">
              Right to Erasure – also referred to as the right to deletion or
              the right to be forgotten – allows you to stop all processing of
              their data and request their personal data be erased.
            </p>
            <p className="container3 reveal fade-left">
              Right to Restrict Data Processing - under certain circumstances,
              you can request that all processing of their personal data be
              stopped.
            </p>
            <p className="container3 reveal fade-left">
              Right to be Notified - you have to be informed about the uses of
              your personal data in a clear manner.
            </p>
            <p className="container3 reveal fade-left">
              Right to Data Portability - you can request that your personal
              data file be sent electronically to a third party.
            </p>
            <p className="container3 reveal fade-left">
              Right to Object - if a request to stop data processing is
              rejected, you have the right to object.
            </p>
            <p className="container3 reveal fade-left">
              Right to Reject Automated Individual Decision-Making - you have
              the right to refuse the automated processing of your personal data
              to make decisions about them if that significantly affects the
              data subject or produces legal effects (profiling).
            </p>
            <p className="container3 reveal fade-left">
              To make such a relevant request for exercising your right(s),
              please use the contact details provided below. We will consider
              and act upon any request in accordance with applicable data
              protection laws.{" "}
            </p>
            <p className="container3 reveal fade-left">
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal, nor will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </p>
            <h1 className="container3 reveal fade-right">
              California consumers’ rights
            </h1>
            <p className="container3 reveal fade-left">
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.{" "}
            </p>
            <p className="container3 reveal fade-left">
              If you are under 18 years of age, reside in California, you have
              the right to request removal of unwanted data that you publicly
              post on the Website. To request removal of such data, please
              contact us using the contact information provided below, and
              include the email address associated with your account and a
              statement that you reside in California. We will make sure the
              data is not publicly displayed on the Website, but please be aware
              that the data may not be completely or comprehensively removed
              from all our systems (e.g. backups, etc.).
            </p>
            <h1 className="container3 reveal fade-right">Other Provisions</h1>
            <p className="container3 reveal fade-left">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.{" "}
            </p>
            <p className="container3 reveal fade-left">
              Disputes related to the fulfillment of this Privacy Policy shall
              be resolved in accordance with the procedure established by the
              current legislation of Germany.
            </p>
            <h1 className="container3 reveal fade-right">
              Updates to Privacy Policy
            </h1>
            <p className="container3 reveal fade-left">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <h1 className="container3 reveal fade-right">
              Contact information
            </h1>
            <p className="container3 reveal fade-left">
              If you have questions or comments about this notice, you may email
              us at{" "}
              <a href="mailto:support@customsurgical.co" className="links_post">
                support@customsurgical.co
              </a>{" "}
              or by post to: Custom Surgical GmbH AtelierstraRe, 29, Munchen,
              81671 Germany.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Warranty;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
